import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Grid"
import { Box, Typography } from "@mui/material"

export const FooterContainer = styled("div")(({ theme }) => ({
  backgroundColor: "#313439",
  height: theme.spacing(52),
  paddingLeft: theme.spacing(16),
  paddingRight: theme.spacing(16),
  boxSizing: "border-box",
  [theme.breakpoints.down("lg")]: {
    height: theme.spacing(61)
  },
  [theme.breakpoints.down("md")]: {
    height: "auto",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
  },
}))

export const FooterWrapper = styled(Grid)(({ theme }) => ({
  height: "100%",
  paddingTop: theme.spacing(10),
  paddingBottom: theme.spacing(6),
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  [theme.breakpoints.down("md")]: {
    alignItems: "left",
    textAlign: "left",
    flexDirection: "column-reverse",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
  },
}))

export const LeftFooterElement = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  [theme.breakpoints.down("lg")]: {
    height: "100%",
  },
  [theme.breakpoints.down("md")]: {
    height: "auto",
  }
}))

export const LeftFooterText1 = styled(Typography)(({theme}) => ({
  color: "#98999C",
  fontSize: theme.spacing(4),
  lineHeight: theme.spacing(4),
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("lg")]: {
    marginBottom: theme.spacing(4),
    fontSize: theme.spacing(3.5),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(3),
    fontSize: theme.spacing(3.5),
  },
}))

export const LeftFooterText2 = styled(Typography)(({theme}) => ({
  color: "#98999C",
  fontSize: theme.spacing(4),
  lineHeight: theme.spacing(4),
  marginBottom: theme.spacing(8),
  [theme.breakpoints.down("lg")]: {
    marginBottom: theme.spacing(4),
    fontSize: theme.spacing(3.5),
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(6),
    fontSize: theme.spacing(3.5),
  },
}))

export const LeftFooterImages = styled(Box)(({theme}) => ({
  width: "100%",
  maxWidth: theme.spacing(82),
  height: theme.spacing(13),
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "transparent",
  gap: theme.spacing(6),
  overflow: "visible",
  [theme.breakpoints.down("lg")]: {
    height: "100%",
    alignItems: "flex-end",
    marginLeft: 0,
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
    justifyContent: "flex-start",
  }
}))

export const ImgTBank = styled("img")(({ theme }) => ({
  width: "100%",
  height: "auto",
  objectFit: "contain",
}))

export const ImgPayingSystems = styled("img")(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: "100%",
  height: "auto",
  objectFit: "contain",
  alignSelf: "flex-end",
}));

export const MiddleFooterElement = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  gap: theme.spacing(6),
  [theme.breakpoints.down("lg")]: {
    gap: theme.spacing(3),
    paddingLeft: theme.spacing(3),
  },
  [theme.breakpoints.down("md")]: {
    alignItems: "flex-start",
    gap: theme.spacing(4),
    marginTop: theme.spacing(13),
    marginBottom: theme.spacing(10),
    paddingLeft: 0,
  },
}))

export const MiddleFooterInternalLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  cursor: "pointer",
  color: "white",
  fontSize: theme.spacing(4.5),
  lineHeight: theme.spacing(4.5),
  fontWeight: "500",
  "&:hover": {
    color: "#FF6557",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: theme.spacing(4),
  }
}))

export const Collaboration = styled("button")(({ theme }) => ({
  background: "none",
  border: "none",
  padding: 0,
  margin: 0,
  textDecoration: "none",
  cursor: "pointer",
  color: "white",
  fontSize: theme.spacing(4.5),
  fontWeight: 550,
  "&:hover": {
    color: "#FF6557",
  },
  [theme.breakpoints.down("xl")]: {
    fontSize: theme.spacing(4)
  }
}))

export const RightFooterElement = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  gap: theme.spacing(2.5),
  [theme.breakpoints.down("lg")]: {
    height: "100%",
    alignItems: "flex-end",
    justifyContent: "flex-start",
  },
  [theme.breakpoints.down("md")]: {
    height: "auto",
    textAlign: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(4),
  }
}))

export const RightFooterLinks = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  paddingTop: 0,
  gap: theme.spacing(3),
  [theme.breakpoints.down("xs")]: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
}))

export const RightFooterIconLinkContainer = styled("div")(({ theme }) => ({
  color: "white",
  fontSize: theme.spacing(3.5),
  flex: "none",
  order: 0,
  flexGrow: 0,
  [theme.breakpoints.down("md")]: {
    marginLeft: 0,
  },
  "& button": {
    [theme.breakpoints.down("md")]: {
      paddingLeft: 0,
    },
  },
}))

export const RightFooterText = styled(Typography)(({theme}) => ({
  color: "#98999C",
  fontSize: theme.spacing(3.5),
  [theme.breakpoints.down("md")]: {
    textAlign: "left",
  },
}))

export const RightFooterPoliciesContainer = styled(Box)(({theme}) => ({
  paddingTop: theme.spacing(6),
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: theme.spacing(6),
  overflow: "visible",
  [theme.breakpoints.down("lg")]: {
    height: "100%",
    width: "auto",
    gap: theme.spacing(6),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(3),
    alignItems: "flex-end"
  },
  [theme.breakpoints.down("md")]: {
    display: "none"
  }
}))

export const MobileFooterPoliciesContainer = styled(Box)(({theme}) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(2.5),
    paddingBottom: theme.spacing(8),
  }
}))

export const LinkElement = styled("a")(({ theme }) => ({
  textDecoration: "underline",
  cursor: "pointer",
  color: "white",
  fontSize: theme.spacing(4),
  whiteSpace: "nowrap",
  "&:hover": {
    color: "#FF6557",
  },
}))
