import React from "react"
import { styled } from "@mui/material/styles"
import { Typography } from "@mui/material"
import { useTheme } from "../../../shared/context"

interface IProps {
  handleChangeRef: (ref: any, index: number) => void
  e: {
    backgroundColor: string
    index: number
    title: {
      text: string
      color: string
    }
    description: {
      text: string
      color: string
    }
  }
}

export const DescriptionBackgroundBlock: React.FC<IProps> = ({ ...props }) => {
  const theme = useTheme()
  return (
    <MainContainer sx={{ backgroundColor: props.e.backgroundColor || "#FFF" }}>
      <Typography
        variant={"h1"}
        sx={{ color: props.e.title.color || "#000", width: "40%", [theme.breakpoints.down("sm")]: { width: "100%" } }}
      >
        {props.e.title.text || "Название блока"}
      </Typography>
      <Typography
        variant={"body1"}
        fontWeight={"medium"}
        fontSize={"large"}
        sx={{
          color: props.e.description.color || "#000",
          width: "40%",
          marginTop: "24px",
          [theme.breakpoints.down("sm")]: { width: "100%" },
        }}
      >
        {props.e.description.text || "Название блока"}
      </Typography>
    </MainContainer>
  )
}

const MainContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  padding: `0 ${theme.spacing(24)}`,
  paddingTop: theme.spacing(24),
  paddingBottom: theme.spacing(24),
  [theme.breakpoints.up("xl")]: {
    paddingLeft: theme.spacing(40),
    paddingRight: theme.spacing(40),
  },
  [theme.breakpoints.down("lg")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(16),
    paddingRight: theme.spacing(16),
    paddingBottom: theme.spacing(16),
  },
  [theme.breakpoints.down("md")]: {
    paddingTop: theme.spacing(16),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
  },
  [theme.breakpoints.down("xs")]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
  },
}))
