import { makeStyles } from "@mui/styles"
import { margin } from "@mui/system"

export default makeStyles(theme => ({
  advantagesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingTop: "64px",
    paddingBottom: "64px",
    marginLeft: "124px",
    marginRight: "124px",
    gap: 48,
    [theme.breakpoints.up("xl")]: {
      paddingLeft: theme.spacing(40),
      marginLeft: 0,
      marginRight: 0,
      paddingRight: theme.spacing(40),
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: 100,
      marginRight: 100,
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: 20,
      marginRight: 20,
    },
  },

  advantagesContentContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-start",
    height: "fit-content",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      gap: 80,
    },
  },

  advantageContainer: {
    "&:hover img": {
      animation: "$rotateImage 2s linear infinite",
    },
    gap: 32,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "32%",
    height: "fit-content",
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      gap: 20,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  imageAdvantage: {
    height: 134,
    boxSizing: "content-box",
    cursor: "pointer",
    "&:hover": {
      animation: "$rotateImage 2s linear infinite",
    },
    [theme.breakpoints.down("sm")]: {
      height: 120,
    },
  },

  "@keyframes rotateImage": {
    from: {
      transform: "rotate(0deg)",
    },
    to: {
      transform: "rotate(360deg)",
    },
  },
}))
