import React from "react";
import {
  DocumentTitle,
  ParagraphTitle,
  Subsection,
  Paragraph,
  BackgroundContainer,
  TermsTextContainer,
} from "./ui/terms.styles"
import { Footer, Header } from "widgets"

const TermsPage = () => {

  const subsections1 = [
    {
      id: "1.1.",
      text: (
        <>
          Права на Сайт в целом, включая его текстовые, графические и иные элементы (в т.ч. результаты интеллектуальной
          деятельности), программы для ЭВМ, обеспечивающие его функционирование, принадлежат ООО «Битроникс»
          (ИНН: 7730192153, ОГРН: 5157746145068, КПП: 504701001, адрес: 141701, Московская область, г. Долгопрудный,
          пр-д Лихачевский, д. 4, стр. 1. оф. 415) (далее – «<strong>Администрация</strong>»). Отдельные права по
          управлению (модерации) Сайта могут быть предоставлены Администрацией иным лицам.
        </>
      ),
    },
    {
      id: "1.2.",
      text: (
        <>
          "На Сайте могут размещаться различные материалы и сервисы, в т.ч. информация в любой форме, графические
          изображения, аудио- и видеозаписи, программы для ЭВМ, иные результаты интеллектуальной деятельности, а также
          прочие объекты (далее – «<strong>Контент</strong>»). Контент в различных случаях может размещаться на Сайте
          Администрацией, Пользователями и третьими лицами."
        </>
      ),
    },
    {
      id: "1.3.",
      text: "Основной целью функционирования Сайта является предоставление Пользователям доступа к Контенту, размещаемому на Сайте, как самой Администрацией, так и третьими лицами, взаимоотношения которых с Администрацией могут регулироваться отдельными соглашениями. Доступ к Контенту может предоставляться Пользователям как на возмездной, так и на безвозмездной основе. Содержание Контента, как правило, носит образовательный характер.",
    },
    {
      id: "1.4.",
      text: "По условиям настоящего Соглашения Администрация предоставляет Пользователю возможность использования Сайта в личных образовательных целях, а Пользователь обязуется соблюдать условия настоящего Соглашения, использовать Сайт исключительно в соответствии с Соглашением. Возможность использования Пользователем отдельных элементов (функционала) Сайта может быть ограничена Администрацией по своему усмотрению или поставлена в зависимость от тех или иных обстоятельств (факта регистрации, внесения платы и др.)."
    },
    {
      id: "1.5.",
      text: "Находясь на интернет-странице Сайта, а также начиная использовать Сайт в любой форме (в т.ч. путем получения доступа к материалам и сервисам Сайта) Пользователь полностью принимает условия настоящего Соглашения без каких-либо оговорок в порядке ст. 438 ГК РФ. В случае несогласия Пользователя с теми или иными условиями настоящего Соглашения он обязан прекратить использование материалов и сервисов Сайта и покинуть Сайт."
    },
    {
      id: "1.6.",
      text: (
        <>
          Настоящее Соглашение может быть в любой момент времени изменено и/или дополнено Администрацией в одностороннем
          порядке без предварительного уведомления Пользователей. Соглашение является общедоступным, его актуальная
          редакция всегда размещена по адресу:{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://syncwoia.com/terms"
            style={{ color: "blue" }}
          >
            https://syncwoia.com/terms
          </a>. По указанному адресу Пользователь может в
          любой момент времени проверить Соглашение на предмет внесения в него Администрацией изменений/дополнений, и
          должен делать это перед очередным началом использования Сайта. Продолжая использовать Сайт (находиться на
          Сайте) после внесения изменений/дополнений в настоящее Соглашение, Пользователь принимает и соглашается с
          указанными изменениями/дополнениями.
        </>
      )
    }
  ]

  const subsections2 = [
    {
      id: "2.1.",
      text: (
        <>
          К числу Пользователей относятся Пользователи, прошедшие процедуру регистрации на Сайте
          (далее – «<strong>Зарегистрированные пользователи</strong>») и Пользователи, не прошедшие процедуру регистрации
          (далее – «<strong>Незарегистрированные пользователи</strong>»).
        </>
      ),
    },
    {
      id: "2.2.",
      text: "Право доступа Пользователей к функционалу Сайта, тем или иным размещенным на нем материалам и сервисам, Контенту может дифференцироваться Администрацией в зависимости от прохождения Пользователем процедуры регистрации.",
    },
    {
      id: "2.3.",
      text: "Проходя процедуру регистрации, Пользователь подтверждает, что достиг возраста, требуемого для заключения настоящего Соглашения и использования Сайта. Несовершеннолетний Пользователь подтверждает, что получил от своего законного представителя согласие на заключение настоящего Соглашения и использование Сайта.",
    },
    {
      id: "2.4.",
      text: "Процедура регистрации представляет собой создание Администрацией Пользователю уникальной учетной записи на Сайте по его заявке. Учетная запись содержит сведения о Пользователе, на основании которых производится его идентификация, в том числе для целей заключения с ним иных соглашений (в частности соглашений, по которым ему предоставляется доступ к Контенту, размещенному на Сайте). Для прохождения процедуры регистрации Пользователь обязан указать в заявке достоверные сведения о нем, запрашиваемые в соответствующей форме на Сайте. Администрация вправе отказать Пользователю в регистрации по своему усмотрению.",
    },
    {
      id: "2.5.",
      text: "В ходе прохождения регистрации Пользователю требуется подтвердить указанный им адрес электронной почты в порядке, определенном Администрацией. Указанный адрес электронной почты впоследствии может быть использован Администрацией для направления Пользователю юридически значимых и иных сообщений. Пользователь подтверждает, что имеет возможность и получает по указанному адресу корреспонденцию, самостоятельно несет риски, связанные с утратой доступа к данному адресу электронной почты, неполучению (несвоевременному получению) на него сообщений Администрации и иных лиц.",
    },
    {
      id: "2.6.",
      text: "В результате прохождения процедуры регистрации логином Пользователя является адрес электронной почты, указанный при регистрации. Пользователь самостоятельно выбирает пароль. Комбинация логина и пароля впоследствии будет использоваться Администрацией для идентификации, аутентификации и авторизации Пользователя на Сайте (входа Пользователя в учетную запись). ",
    },
    {
      id: "2.7.",
      text: "Пользователь обязан сохранять в тайне от третьих лиц комбинацию присвоенных ему логина и пароля, самостоятельно обеспечивает защиту их конфиденциальности. Администрация не несет ответственности за получение третьими лицами несанкционированного доступа к учетной записи Пользователя на Сайте в случае предоставления такими лицами надлежащей комбинации логина и пароля Пользователя.",
    },
    {
      id: "2.8.",
      text: "В случае утраты Пользователем логина/пароля от его учетной записи, Администрация вправе восстановить доступ Пользователя к учетной записи (в т.ч. присвоить ему новую комбинацию логина и пароля). Восстановление доступа к учетной записи Пользователя осуществляется путем связи с ним посредством указанных им при регистрации адреса электронной почты или номеру мобильного телефона. В целях восстановления доступа к учетной записи Пользователя Администрация вправе запрашивать у него сведения о его личности, в том числе данные документов, удостоверяющих личность.",
    },
    {
      id: "2.9.",
      text: "Пользователю предоставляется возможность в любой момент изменять указанные им при регистрации сведения номер телефона, организацию, населенный пункт, информацию о себе и пр.) в настройках его учетной записи на Сайте (раздел «Профиль»). Пользователь обязан обеспечить достоверность сведений, содержащихся в его учетной записи. В случае утраты Пользователем доступа к указанным им в качестве контактного номера телефона, Пользователь обязан незамедлительно удалить его в настройках учетной записи. В противном случае, Администрация не несет ответственности за возможное получение доступа к учетной записи Пользователя третьими лицами и не гарантирует Пользователю возможность восстановления доступа к его учетной записи.",
    },
    {
      id: "2.10.",
      text: "Пользователь вправе потребовать удаления его учетной записи путем направления уведомления на адрес электронной почты Администрации: support@syncwoia.com.",
    },
    {
      id: "2.11.",
      text: "Пользователь не вправе передавать или предоставлять третьим лицам доступ к своей учетной записи, в том числе в целях обеспечения доступа таких лиц к Контенту, размещенному на Сайте.",
    },
  ]

  const subsections3 = [
    {
      id: "3.1.",
      indentLevel: 0,
      text: "Контент может размещаться на Сайте Администрацией, Пользователями, а также третьими лицами.",
    },
    {
      id: "3.2.",
      indentLevel: 0,
      text: "Администрация не обязана отслеживать содержание всего Контента, размещаемого на Сайте Пользователями, а также третьими лицами, не несет ответственности за такой Контент. ",
    },
    {
      id: "3.3.",
      indentLevel: 0,
      text: "В случае нарушения Контентом, размещенным на Сайте, требований настоящего Соглашения, законодательства РФ и/или прав и законных интересов третьих лиц, он может быть удален или изменен Администрацией, как по ее усмотрению, так и по заявлению заинтересованного лица. О наличии в том или ином размещенном на Сайте Контенте соответствующих нарушений Администрация может быть уведомлена по электронной почте: support@syncwoia.com.",
    },
    {
      id: "3.4.",
      indentLevel: 0,
      text: "На Сайте могут быть размещены ссылки на сторонние интернет-страницы, не принадлежащие и не контролируемые Администрацией. Администрация не несет ответственности за содержание не принадлежащих ей интернет-страниц, в том числе за их достоверность или безопасность для Пользователя.",
    },
    {
      id: "3.5.",
      indentLevel: 0,
      text: "Если Пользователю предоставлена техническая возможность самостоятельно размещать какой-либо Контент на Сайте, ответственность за его соответствие требованиям настоящего Соглашения, законодательству РФ и ненарушение им прав и законных интересов третьих лиц несет разместивший его Пользователь.",
    },
    {
      id: "3.6.",
      indentLevel: 0,
      text: "Любой размещаемый на Сайте Контент не должен:",
    },
    {
      id: "3.6.1.",
      indentLevel: 1,
      text: "содержать оскорбления, призывы к насилию или жестокости, дискриминировать те или иные социальные группы по расовому, этническому, половому, религиозному или иным признакам;",
    },
    {
      id: "3.6.2.",
      indentLevel: 1,
      text: "являться рекламой или содержать способы или порядок изготовления или использования запрещенных объектов, изъятых или ограниченных в обороте на территории РФ (наркотических средств, взрывчатых веществ, оружия и пр.);",
    },
    {
      id: "3.6.3",
      indentLevel: 1,
      text: "содержать в себе результаты интеллектуальной деятельности и приравненные к ним средства индивидуализации, права на размещение которых в соответствующей форме отсутствуют у размещающих их лиц;",
    },
    {
      id: "3.6.4.",
      indentLevel: 1,
      text: "содержать сведения, составляющие личную, семейную, медицинскую, банковскую, государственную, коммерческую или иную охраняемую законом тайну;",
    },
    {
      id: "3.6.5.",
      indentLevel: 1,
      text: "содержать вредоносные компьютерные программы (коды), программное обеспечение, которое может быть использовано для нарушения нормальной работы Сайта или устройств Пользователей или третьих лиц, хищения данных третьих лиц или для совершения иных правонарушений;",
    },
    {
      id: "3.6.6",
      indentLevel: 1,
      text: "содержать персональные данные лиц, не предоставивших согласие на их распространение соответствующим образом;",
    },
    {
      id: "3.6.7.",
      indentLevel: 1,
      text: "представлять собой спам, не согласованную с Администрацией рекламную информацию;",
    },
    {
      id: "3.6.8.",
      indentLevel: 1,
      text: "нарушать права и законные интересы третьих лиц, в том числе в области интеллектуальной собственности;",
    },
    {
      id: "3.6.9.",
      indentLevel: 1,
      text: "нарушать требования законодательства Российской Федерации;",
    },
    {
      id: "3.6.10.",
      indentLevel: 1,
      text: "нарушать иные условия настоящего Соглашения;",
    },
    {
      id: "3.6.11.",
      indentLevel: 1,
      text: "содержать ссылки на иные интернет-страницы, содержащие запрещенный настоящим Соглашением Контент",
    },
    {
      id: "3.7.",
      indentLevel: 0,
      text: "Администрация вправе в любой момент времени отказать Пользователю или любому иному лицу в размещении на Сайте любого Контента, ограничить доступ к нему, удалить или изменить любой уже размещенный на Сайте Контент. Указанные действия могут быть совершены Администрацией, в том числе в случае несоответствия Контента требованиям настоящего Соглашения, законодательства РФ и/или нарушения им прав и законных интересов третьих лиц.",
    },
    {
      id: "3.8.",
      indentLevel: 0,
      text: "Пользователь осведомлен, что любой размещенный на Сайте Контент является объектом исключительных прав Администрации, других Пользователей или третьих лиц, охраняется законодательством об авторском праве. Использование Пользователем материалов и сервисов Сайта, размещенного на нем Контента, возможно исключительно в случае, если такое право предоставлено Пользователю, только в целях, в которых ему предоставлен такой доступ, и в пределах функционала сайта. Не допускается использование Сайта и Контента в иных целях.",
    },
    {
      id: "3.9.",
      indentLevel: 0,
      text: "Ни одно положение настоящего Соглашения не может быть истрактовано как предоставляющее Пользователю права использования фирменного или коммерческого наименования, товарных знаков, доменных имен, иных результатов интеллектуальной деятельности и приравненных к ним средств индивидуализации, права на которые принадлежат Администрации или третьим лицам, если иное прямо и недвусмысленно не предусмотрено Соглашением.",
    },
  ]

  const subsections4 = [
    {
      id: "4.1.",
      indentLevel: 0,
      text: "Пользователь вправе:",
    },
    {
      id: "4.1.1.",
      indentLevel: 1,
      text: "Использовать Сайт в целях, для достижения которых ему предоставлена возможность его использования;",
    },
    {
      id: "4.1.2.",
      indentLevel: 1,
      text: "Получать доступ к Контенту, размещенному на Сайте, в порядке и на условиях, предусмотренных настоящим Соглашением и/или иными договорами.",
    },
    {
      id: "4.2.",
      indentLevel: 0,
      text: "Пользователь обязан:",
    },
    {
      id: "4.2.1.",
      indentLevel: 1,
      text: "Использовать Сайт, а также содержащийся на Сайте Контент исключительно в целях, для которых ему предоставлен к ним доступ;",
    },
    {
      id: "4.2.2.",
      indentLevel: 1,
      text: "Обеспечивать актуальность и достоверность сведений, содержащихся в его учетной записи;",
    },
    {
      id: "4.2.3.",
      indentLevel: 1,
      text: "Помнить уникальную комбинацию логина и пароля, используемую для входа в учетную запись;",
    },
    {
      id: "4.2.4.",
      indentLevel: 1,
      text: "Не сообщать третьим лицам комбинацию логина и пароля от своей учетной записи, а также информацию, направляемую ему Администрацией в ходе подтверждения адреса электронной почты или восстановления доступа к учетной записи (в т.ч. проверочные коды и ссылки);",
    },
    {
      id: "4.2.5.",
      indentLevel: 1,
      text: "Самостоятельно принимать меры, направленные на обеспечение безопасности учетной записи (в т.ч. конфиденциальности логина и пароля) и предотвращение несанкционированного доступа к учетной записи третьих лиц;",
    },
    {
      id: "4.2.6.",
      indentLevel: 1,
      text: "Незамедлительно сообщать Администрации о нарушении конфиденциальности логина и пароля или иной угрозе безопасности его учетной записи;",
    },
    {
      id: "4.2.7.",
      indentLevel: 1,
      text: "Соблюдать иные условия настоящего Соглашения и действующего законодательства РФ.",
    },
    {
      id: "4.3.",
      indentLevel: 0,
      text: "При использовании Сайта Пользователь не вправе:",
    },
    {
      id: "4.3.1.",
      indentLevel: 1,
      text: "Передавать свою учетную запись в пользование другим лицам, осуществлять одновременный доступ к учетной записи с нескольких устройств;",
    },
    {
      id: "4.3.2.",
      indentLevel: 1,
      text: "Создавать более одной учетной записи на Сайте;",
    },
    {
      id: "4.3.3",
      indentLevel: 1,
      text: "Выдавать себя за иных Пользователей, третьих лиц, представителя Администрации, распространять ложную информацию, вводить кого-либо в заблуждение;",
    },
    {
      id: "4.3.4.",
      indentLevel: 1,
      text: "Осуществлять действия или содействовать действиям, направленным на нарушение нормальной работы Сайта, взламывать Сайт;",
    },
    {
      id: "4.3.5.",
      indentLevel: 1,
      text: "Использовать Сайт, его структурные элементы, материалы, сервисы, инфраструктуру (в т.ч. используемое для обеспечения его функционирования программное обеспечение) в предпринимательских и иных целях, за исключением прямо предусмотренных настоящим Соглашением или иным договором. А также копировать, воспроизводить, распространять, реализовывать и производить иные действия с указанными объектами;",
    },
    {
      id: "4.3.6.",
      indentLevel: 1,
      text: "Размещать на Сайте Контент, не соответствующий требованиям настоящего Соглашения, в том числе Контент, право на размещение которого у Пользователя отсутствует;",
    },
    {
      id: "4.3.7.",
      indentLevel: 1,
      text: "Использовать Контент, размещенный на Сайте без согласия правообладателя;",
    },
    {
      id: "4.3.8.",
      indentLevel: 1,
      text: "Собирать, хранить, распространять или иным образом обрабатывать персональные данные третьих лиц;",
    },
    {
      id: "4.3.9.",
      indentLevel: 1,
      text: "Осуществлять цитирование материалов Сайта без ссылки на Сайт;",
    },
    {
      id: "4.3.10.",
      indentLevel: 1,
      text: "Иным образом нарушать действующее законодательство РФ или права и законные интересы третьих лиц.",
    },
    {
      id: "4.4.",
      indentLevel: 0,
      text: "Пользователь несет всю ответственность за любые действия, совершаемые на Сайте с использованием его учетной записи, в том числе за направленные Администрации и иными лицам сообщения, совершенные акцепты и размещенный на Сайте Контент. Пользователь не вправе ссылаться на совершение указанных действий третьими лицами, если он своевременно (до совершения таких действий) не уведомил Администрацию об угрозе безопасности его учетной записи.",
    },
    {
      id: "4.5.",
      indentLevel: 0,
      text: "В случае нарушения Пользователем тех или иных условий настоящего Соглашения (включая нарушение любого условия, указанного в настоящем разделе 4) Администрация вправе ограничить или полностью заблокировать ему доступ ко всему Сайту, к отдельным его функциональным элементам, к Контенту (в том числе, к приобретенному Пользователем на возмездной основе), удалить (заблокировать) учетную запись Пользователя. Администрация вправе также удалять, блокировать, приостанавливать действие и совершать иные действия с учетной записью Пользователя в целях обеспечения ее безопасности, безопасности Сайта, недопущения нарушения прав третьих лиц и в иных случаях. Принимая настоящее Соглашение, Пользователь соглашается с возможностью применения к его учетной записи любых ограничений в т.ч. без его предварительного уведомления и объяснения причин.",
    },
    {
      id: "4.6.",
      indentLevel: 0,
      text: "При использовании Сайта не допускается использование компьютерных программ, предназначенных для получения автоматического доступа к Сайту, регистрации учетных записей на Сайте (в т.ч. «ботов»). Администрация вправе ограничивать и блокировать доступ Пользователей к Сайту (в т.ч. удалять учетные записи) при наличии подозрений обращения к нему в автоматическом режиме. Администрация вправе ограничивать получение сообщений, электронных запросов от Пользователя в целях защиты работоспособности Сайта, своего оборудования (в т.ч. от DoS, DDoS-атак).",
    },
  ]

  const subsections5 = [
    {
      id: "5.1.",
      indentLevel: 0,
      text: (
        <>
          На Сайте может осуществляться реализация различных продуктов (включающих в себя объекты, услуги, товары и др.):
          онлайн-курсов, мастер-классов, программ, консультаций, оборудования, билетов на мероприятия и пр.
          (далее – «<strong>Продукты</strong>»).
        </>
      ),
    },
    {
      id: "5.2.",
      indentLevel: 0,
      text: (
        <>
          Реализация Продуктов с использованием Сайта может осуществляться как непосредственно Администрацией,
          так и третьими лицами, в т.ч. самими Пользователями (далее – «<strong>Лицо, реализующее Продукт</strong>»).
        </>
        ),
    },
    {
      id: "5.3.",
      indentLevel: 0,
      text: "Если в качестве Лица, реализующего Продукты, выступает не сама Администрация, а третье лицо, Администрация не несет ответственности за исполнение Лицом, реализующим Продукт, своих обязательств по договору, заключенному с Пользователем, не несет убытки, связанные с его неисполнением (ненадлежащим исполнением). В этом случае Администрация обязуется исключительно обеспечить Пользователю доступ к загруженному Лицом, реализующим Продукты, на Сайт Контенту (при условии соблюдения Пользователем условий настоящего Соглашения). Обязательства перед Пользователем несет непосредственно Лицо, реализующее Продукты. Кто является Лицом, реализующим Продукты, указано на странице Продукта.",
    },
    {
      id: "5.4.",
      indentLevel: 0,
      text: "Реализация Продуктов Пользователю осуществляется на основе договоров, заключаемых между Пользователем и Лицами, реализующими Продукты, в электронной форме путем принятия (акцепта) Пользователем условий договора (оферты), размещенных на Сайте. Условия такого договора складываются из:",
      hasList: true,
      list: [
        "описания Продукта, размещенного на странице Сайта;",
        "условий, указанных в настоящем Соглашении.",
      ],
    },
    {
      id: "5.5.",
      indentLevel: 0,
      text: "Для целей заключения соответствующего договора реализации Продукта данные Пользователя определяются в соответствии с указанными в его учетной записи на Сайте, данные Лица, реализующего Продукты, – в соответствии с описанием реализуемого Продукта, размещенном на Сайте. Лицо, реализующее Продукты, может быть обозначено в описании Продукта как «организатор курса», «продавец», «автор», «команда проекта» или иным подобным образом.",
    },
    {
      id: "5.6.",
      indentLevel: 0,
      text: "Содержание Продукта (предмет договора), его стоимость (цена договора) и иные условия реализации определяются исходя из описания соответствующего Продукта на Сайте. Условия, указанные в описании Продукта на Сайте формируются Лицом, реализующим Продукт. Администрация не отвечает за достоверность указанных условий, если Лицом, реализующим Продукты, выступает не сама Администрация, а иное лицо. Условия Реализации Продукта определяются также настоящим Соглашением вне зависимости от того, кто выступает Лицом, реализующим Продукты, в том или ином случае (Администрация или третье лицо).",
    },
    {
      id: "5.7.",
      indentLevel: 0,
      text: "Ввиду различного содержания Продуктов, договоры об их реализации, заключаемые между Пользователем и Лицом, реализующим Продукты, могут содержать признаки различных договоров (возмездного оказания услуг, купли-продажи, лицензионных договоров и др.), являться смешанными договорами.",
    },
    {
      id: "5.8.",
      indentLevel: 0,
      text: "По общему правилу договор между Пользователем и Лицом, реализующим Продукты, считается заключенным на условиях, указанных в настоящем пункте. Если иные условия такого договора (оферты) прямо указаны в описании Продукта, размещенном на Сайте, данные условия заменяют условия, указанные в настоящем пункте, в соответствующей части.",
    },
    {
      id: "5.8.1.",
      indentLevel: 1,
      text: "В случае если Пользователю предоставляется доступ к размещенному на Сайте Контенту (онлайн-курсу, мастер-классу и т.п.), предметом такого договора выступает обязанность Лица, реализующего Продукты, оказать Пользователю услугу по предоставлению доступа к Контенту. Пользователю не предоставляются какие-либо  исключительные или иные права (использования) на результаты интеллектуальной деятельности, входящие в Контент, если иное прямо и недвусмысленно не предусмотрено в описании Продукта;",
    },
    {
      id: "5.8.2.",
      indentLevel: 1,
      text: "Если, исходя из природы Продукта и его описания, к договору о его реализации применимы положения о договоре оказания услуг (в т.ч. оказания образовательных услуг, консультационных услуг и др.), договор между Пользователем и Лицом, реализующим Продукты, о реализации Продукта считается заключенным на следующих условиях:",
    },
    {
      id: "5.8.2.1.",
      indentLevel: 2,
      text: "Лицо, реализующее Продукты, обязуется оказать Пользователю услуги, указанные в описании Продукта на Сайте, а Пользователь обязуется их принять и оплатить (если услуги оказываются возмездно);",
    },
    {
      id: "5.8.2.2.",
      indentLevel: 2,
      text: "В случае предоставления Пользователю доступа к Контенту, его использование Пользователем возможно исключительно в личных образовательных целях, без права использования его в иных (в т.ч. предпринимательских) целях, без права передачи (предоставления) содержания Контента третьим лицам. Пользователь осознает, что Контент и все входящие в него элементы (результаты интеллектуальной деятельности) являются объектом охраны авторского права, исключительные права на которые принадлежат Лицу, реализующему Продукт (или иным третьим лицам). Пользователь обязуется не совершать действий, направленных на нарушение прав таких лиц на Контент и входящие в него объекты, в том числе не распространять их без согласия правообладателя;",
    },
    {
      id: "5.8.2.3.",
      indentLevel: 2,
      text: "Если иное прямо не указано в описании Продукта, Лицо, реализующее Продукты, не обладает лицензией на осуществление образовательной деятельности, не выдает документы об образовании государственного образца;",
    },
    {
      id: "5.8.2.4.",
      indentLevel: 2,
      text: "Если иное прямо не указано в описании Продукта, по результатам оказания услуг Пользователю не выдается документ об образовании, сертификат о прохождении курса или иной подобный документ;",
    },
    {
      id: "5.8.2.5.",
      indentLevel: 2,
      text: "Пользователю не гарантируется достижение какого-либо конкретного результата по итогам оказания услуг. Достижение того или иного результата по итогам оказания услуг (например, образовательного) зависит в том числе от усилий самого Пользователя. Информация, используемая в ходе оказания услуг, предоставляется Пользователю «как есть», Лицо, реализующее Продукты, не гарантирует ее уникальность;",
    },
    {
      id: "5.8.2.6.",
      indentLevel: 2,
      text: "Договор считается исполненным Лицом, реализующим Продукты, с момента оказания им Пользователю услуг в объеме, установленном в описании Продукта (например, проведения определенного числа занятий);",
    },
    {
      id: "5.8.2.7.",
      indentLevel: 2,
      text: "С помощью функционала Сайта может осуществляться фиксация факта оказания Пользователю услуг. Услуги, в том числе, считаются оказанными, если они были оказаны иному лицу, получившему их через учетную запись Пользователя. Пользователь не вправе ссылаться на неоказание ему услуг, в случае, если услуги оказывались иному лицу, использовавшему учетную запись Пользователя, Пользователь признает такое исполнение надлежащим;",
    },
    {
      id: "5.8.2.8.",
      indentLevel: 2,
      text: "Услуги в любом случае считаются принятыми Пользователем как оказанные в полном объеме и в надлежащем качестве по истечении 3 (трех) календарных дней с даты их оказания, если Пользователем в указанный срок не направлены соответствующие возражения (претензия).",
    },
    {
      id: "5.8.3.",
      indentLevel: 1,
      text: "Если, исходя из природы Продукта и его описания, к договору о его реализации применимы положения о лицензионном договоре (по договору Пользователю предоставляется право использования результатов интеллектуальной деятельности), договор между Пользователем и Лицом, реализующим Продукты, о реализации Продукта считается заключенным на следующих условиях:",
    },
    {
      id: "5.8.3.1.",
      indentLevel: 2,
      text: "Пользователю предоставляется право использования того или иного объекта исключительно в личных образовательных целях, без права использования его в иных (в т.ч. предпринимательских) целях, без права передачи этого права или передачи содержания самого Контента третьим лицам, без права выдачи сублицензий, право предоставляется Пользователю на условиях простой неисключительной лицензии (за Лицом, реализующим Продукт, остается право предоставлять аналогичное право использования иным лицам);",
    },
    {
      id: "5.8.3.2.",
      indentLevel: 2,
      text: "Пользователю не передаются исключительные права на какие-либо результаты интеллектуальной деятельности (Контент), не предоставляются права использования и какие-либо иные права на любые (в т.ч. входящие в Контент) результаты интеллектуальной деятельности и приравненные к ним средства индивидуализации за исключением права, указанного в п. 5.8.3.1 настоящего Соглашения;",
    },
    {
      id: "5.8.3.3.",
      indentLevel: 2,
      text: "Реализация предоставляемого Пользователю права использования (доступ Пользователя к Контенту) осуществляется через Сайт с привязкой права к учетной записи Пользователя. В связи с этим Пользователь принимает и обязуется соблюдать условия настоящего Соглашения, запомнить необходимые для доступа к его учетной записи комбинацию логина и пароля;",
    },
    {
      id: "5.8.3.4.",
      indentLevel: 2,
      text: "Право использования предоставляется Пользователю на 3 (трех) месяцев, при условии соблюдения им условий договора, а также условий, содержащихся в настоящем пользовательском Соглашении. Предоставленное Пользователю право использования, может быть в любой момент без какой-либо компенсации ограничено или прекращено в случае, если Пользователь использует Контент в неправомерных целях, нарушает права и законные интересы третьих лиц, требования законодательства РФ или настоящего Соглашения (в т.ч. в случае блокировки (удаления) учетной записи Пользователя на Сайте);",
    },
    {
      id: "5.8.3.5.",
      indentLevel: 2,
      text: "Пользователь самостоятельно несет ответственность в случае нарушения им в ходе использования предоставленного ему права (Контента к которому ему предоставлен доступ) законодательства РФ, прав и законных интересов третьих лиц;",
    },
    {
      id: "5.8.3.6.",
      indentLevel: 2,
      text: "Договор считается исполненным Лицом, реализующим Продукты, с момента предоставления Пользователю доступа к Контенту (с момента открытия технической возможности просмотра Контента для учетной записи Пользователя);",
    },
    {
      id: "5.8.3.7.",
      indentLevel: 2,
      text: "Контент предоставляется Пользователю «как есть», Пользователю не гарантируется достижение по итогам использования Контента какого-либо конкретного результата;",
    },
    {
      id: "5.8.4.",
      indentLevel: 1,
      text: "Если, исходя из природы Продукта и его описания, к договору о его реализации применимы положения о договоре купли-продажи (в т.ч. при продаже Пользователю оборудования, иных товаров), договор между Пользователем и Лицом, реализующим Продукты, о реализации Продукта считается заключенным на следующих условиях:",
    },
    {
      id: "5.8.4.1.",
      indentLevel: 2,
      text: "Лицо, реализующее Продукты, обязуется передать Пользователю товар, указанный в описании Продукта на Сайте;",
    },
    {
      id: "5.8.4.2.",
      indentLevel: 2,
      text: "В случае если по условиям договора товар доставляется Пользователю, расходы на доставку товара несет Пользователь;",
    },
    {
      id: "5.8.4.3.",
      indentLevel: 2,
      text: "Если иное не предусмотрено описанием Продукта, товар реализуется в количестве 1 шт;",
    },
    {
      id: "5.8.4.4.",
      indentLevel: 2,
      text: "При получении товара Пользователь обязан принять его, осмотреть и проверить его функциональность, при обнаружении в ходе приемки недостатков товара Пользователь обязан незамедлительно уведомить об этом Лицо, реализующее Продукт.",
    },
    {
      id: "5.8.5.",
      indentLevel: 1,
      text: "Независимо от природы Продукта договор о его реализации в любом случае считается заключенным на следующих условиях:",
    },
    {
      id: "5.8.5.1.",
      indentLevel: 2,
      text: "Право использования предоставляется (услуга оказывается, товар передается) Пользователю на возмездной основе, если в описании Продукта не указано, что он реализуется безвозмездно;",
    },
    {
      id: "5.8.5.2",
      indentLevel: 2,
      text: "Право использования (право доступа к Контенту) предоставляется (услуга оказывается, товар передается) после внесения Пользователем 100% предоплаты; оплата цены договора осуществляется Пользователем в безналичной форме с использованием Сайта;",
    },
    {
      id: "5.8.5.3.",
      indentLevel: 2,
      text: "Использование Продукта (реализация предоставляемого Пользователю права использования, права доступа к Контенту, оказание Пользователю услуг и т.п.) осуществляется через Сайт с привязкой права к учетной записи Пользователя. В связи с этим Пользователь принимает и обязуется соблюдать условия настоящего Соглашения, запомнить необходимые для доступа к его учетной записи комбинацию логина и пароля. Ответственность за сохранность и конфиденциальность указанных сведений несет Пользователь. Утрата Пользователем доступа его к учетной записи не влечет возникновения у Лица, реализующего Продукты, или Администрации обязанностей по возврату Пользователю цены договора, возмещения ему убытков, выплаты компенсаций, неустоек или возникновению у указанных лиц ответственности в иной форме;",
    },
    {
      id: "5.8.5.4.",
      indentLevel: 2,
      text: "Продукт предоставляется Пользователю при условии соблюдения им условий договора, а также настоящего пользовательского Соглашения. В случае нарушения Пользователем условий договора или настоящего Соглашения, неправомерного использования им Продукта, нарушения им прав и законных интересов третьих лиц или законодательства РФ Администрация вправе ограничить доступ Пользователя к его учетной записи, блокировать (удалить) учетную запись Пользователя, совершить иные необходимые действия. Указанные действия Администрации не считаются нарушением условий договора, заключенного между Лицом, реализующим Продукты, и Пользователем и не влекут возникновения у Лица, реализующего Продукты, или Администрации обязанностей по возврату Пользователю цены договора, возмещения ему убытков, выплаты компенсаций, неустоек или возникновению у указанных лиц ответственности в иной форме;",
    },
    {
      id: "5.8.5.5.",
      indentLevel: 2,
      text: "Правила отказа от приобретения права использования (услуги, товара), их возврата (обмена) определяются в соответствии с положениями настоящего Соглашения и законодательства РФ.",
    },
    {
      id: "5.9.",
      indentLevel: 0,
      text: "Порядок оформления приобретения Пользователем Продукта с использованием Сайта:",
    },
    {
      id: "5.9.1.",
      indentLevel: 1,
      text: "Пользователь выбирает интересующий его Продукт и направляет заявку на его приобретение с использованием Сайта;",
    },
    {
      id: "5.9.2.",
      indentLevel: 1,
      text: "После направления заявки Пользователь осуществляет оплату стоимости приобретаемого им Продукта в безналичной форме. Для осуществления оплаты Пользователь перенаправляется на интернет-страницу оплаты Продукта, которая может принадлежать третьим лицам. В случае если оплата стоимости Продукта производится не самим Пользователем, а иными лицами (с банковских счетов иных лиц), права и обязанности по договору о реализации Продукта, тем не менее, возникают непосредственно у самого Пользователя;",
    },
    {
      id: "5.9.3.",
      indentLevel: 1,
      text: "Обязательства между Пользователем и Лицом, реализующим Продукты, возникают с момента получения Лицом, реализующим Продукты, денежных средств. После получения оплаты Пользователю предоставляется доступ к соответствующему Продукту в соответствии с условиями, указанными в его описании на Сайте и условиями настоящего Соглашения;",
    },
    {
      id: "5.9.4.",
      indentLevel: 1,
      text: "Если из описания на Сайте не следует иное, доступ к Продукту предоставляются Пользователю с использованием Сайта с привязкой к его учетной записи, указанной им при оформлении заявки. Администрация не несет ответственности за утрату Пользователем комбинации логина и пароля, необходимых для доступа к его учетной записи.",
    },
    {
      id: "5.10.",
      indentLevel: 0,
      text: "Условия отказа от Продукта, возврата и обмена товаров, работ, услуг, приобретенных с использованием Сайта.",
    },
    {
      id: "5.10.1.",
      indentLevel: 1,
      text: "Пользователь вправе отказаться от приобретения Продукта до момента предоставления ему доступа к нему (до момента открытия технической возможности доступа к Продукту для учетной записи Пользователя). ",
    },
    {
      id: "5.11.",
      indentLevel: 0,
      text: "Порядок отказа от приобретения Продукта.",
    },
    {
      id: "5.11.1.",
      indentLevel: 1,
      text: "При наличии у Пользователя соответствующих прав, Пользователь может отказаться от приобретения доступа к Контенту, потребовать возврата и обмена товаров, работ, услуг, приобретенных с использованием Сайта путем направления Администрации соответствующей заявки по электронной почте: support@syncwoia.com. В случае если Лицом, реализующим Продукты, выступило третье лицо, Администрация перенаправляет заявку Пользователя этому лицу;",
    },
    {
      id: "5.11.2.",
      indentLevel: 1,
      text: "После принятия решения по заявке Пользователя, Администрация направляет ему ответ на указанный Пользователем адрес электронной почты. В случае одобрения заявки, возврат Пользователю денежных средств осуществляется в течение 15 (пятнадцати) рабочих дней с даты направления Администрацией Пользователю ответа.",
    },
  ]

  const subsections6 = [
    {
      id: "6.1.",
      indentLevel: 0,
      text: "Отношения между Администрацией и Лицами, реализующими Продукты с использованием Сайта, регулируются настоящим Соглашением, а также иными договорами, заключаемыми между Администрацией и Лицами, реализующими Продукты.",
    },
    {
      id: "6.2.",
      indentLevel: 0,
      text: "Договорами между Администрацией и Лицами, реализующими Продукты, могут быть установлены отдельные условия, отличные от условий настоящего Соглашения. В части, не урегулированной такими договорами, применяются условия настоящего Соглашения.",
    },
    {
      id: "6.3.",
      indentLevel: 0,
      text: "Предложение Администрации, адресованное третьим лицам, стать Лицами, реализующими Продукты с использованием Сайта, не является публичной офертой. Администрация вправе отказать любому лицу в размещении его Продуктов на Сайте.",
    },
    {
      id: "6.4.",
      indentLevel: 0,
      text: "За предоставление права использования Сайта в целях размещения и реализации на нем Продуктов, Лицо, реализующее Продукты, обязуется выплачивать Администрации вознаграждение (лицензионные платежи). Размер лицензионных платежей определяется в соглашении между Администрацией и Лицом, реализующим Продукты, и может зависеть от числа реализованных с использованием Сайта Продуктов.",
    },
    {
      id: "6.5.",
      indentLevel: 0,
      text: "Администрация вправе принимать от Пользователей оплату за приобретение ими Продуктов у Лиц, реализующих Продукты, удерживать из указанной оплаты причитающиеся Администрации суммы вознаграждений по настоящему Соглашению, неустоек и любых иных платежей, после чего перечислять оставшуюся часть суммы Лицу, реализующему Продукты.",
    },
    {
      id: "6.6.",
      indentLevel: 0,
      text: "Лицо, реализующее Продукты, вправе использовать Сайт исключительно в целях реализации Пользователям Продуктов. К Лицу, реализующему Продукты, применяются положения настоящего Соглашения, применимые к Пользователю (за исключением указанных в разделе 5), если иное не предусмотрено Соглашением или иным договором. Аналогичным образом к отношениям между Лицом, реализующим Продукты, и Администрацией применяются положения об ответственности Администрации (раздел 7 Соглашения). Лицо, реализующее Продукты, в том числе, несет обязанности Пользователя и обязано соблюдать наложенные на него запреты.",
    },
    {
      id: "6.7.",
      indentLevel: 0,
      text: "В случае нарушения Лицом, реализующим Продукты, условий настоящего Соглашения, включая, но не ограничиваясь, указанием недостоверных сведений при регистрации учетной записи (раздел 2 Соглашения), нарушением правил размещения и использования Контента (раздел 3 Соглашения), нарушением запретов и невыполнением иных обязанностей (раздел 4 Соглашения), учетная запись Лица, реализующего Продукты, может быть заблокирована (удалена) Администрацией, доступ к ней Лица, реализующего Продукты, может быть ограничен.",
    },
    {
      id: "6.8.",
      indentLevel: 0,
      text: "Блокировка (удаление) учетной записи Лица, реализующего Продукты, равно как и ограничение доступа этого лица к ней, является самостоятельным основанием для одностороннего отказа Администрации от исполнения настоящего Соглашения (расторжения настоящего Соглашения) в одностороннем внесудебном порядке, а также для одностороннего внесудебного отказа Администрации от исполнения (расторжения) иных связанных с настоящим Соглашением договоров, заключенных между Администрацией и Лицом, реализующим Продукты.",
    },
    {
      id: "6.9.",
      indentLevel: 0,
      text: "Лица, реализующие Продукты, подтверждают, что условия договоров, заключаемых между ними и Пользователями, определяются настоящим Соглашением. Условия, указанные в п. 5.8 настоящего Соглашения могут быть изменены ими путем внесения иных условий в описание реализуемого ими Продукта на соответствующей странице Сайта. Обязанности Пользователей по соблюдению условий настоящего Соглашения, не указанных в п. 5.8, не могут быть изменены путем внесения иных условий в описание Продукта.",
    },
    {
      id: "6.10.",
      indentLevel: 0,
      text: "Лица, реализующие Продукты самостоятельно формируют описание Продукта, самостоятельно осуществляют публикацию Контента на Сайте и устанавливают режим доступа к нему (на возмездной или безвозмездной основе, стоимость предоставления доступа). В связи с изложенным, Лица, реализующие Продукты, самостоятельно несут риски, связанные с получением неограниченного числа лиц доступа к размещенному ими Контенту, в том числе с получением несанкционированного доступа к нему.",
    },
    {
      id: "6.11.",
      indentLevel: 0,
      text: "Администрация вправе перенаправлять полученные ей от Пользователей претензии в адрес Лиц, реализующих Продукты, которым они были адресованы. В этом случае, Лицо, реализующее Продукты, обязуется не позднее одного рабочего дня с даты получения претензии, предоставить Администрации ответ на соответствующую претензию для перенаправления его Пользователю.",
    },
    {
      id: "6.12.",
      indentLevel: 0,
      text: "В случае возникновения спора между Пользователем и Лицом, реализующим Продукты, касающегося исполнения договора, заключенного с использованием Сайта (в т.ч. связанного с возвратом, обменом Продукта, возвратом его стоимости Пользователю) Администрация вправе по своему усмотрению разрешить указанный спор. В случае разрешения Администрацией спора в пользу Пользователя, Лицо, реализующее Продукты, обязуется выплатить Пользователю причитающиеся ему суммы. Администрация также вправе выплатить причитающиеся Пользователю суммы самостоятельно. В последнем случае у Лица, реализующего Продукты, возникает обязательство по возмещению Администрации соответствующих сумм в течение 10 календарных дней. Администрация вправе в порядке, установленном п. 6.5 Соглашения, удержать указанную сумму из суммы платежа, адресованного Лицу, реализующему Продукты, в том числе из суммы, поступившей от другого Пользователя.",
    },
    {
      id: "6.13.",
      indentLevel: 0,
      text: "Лицо, реализующее Продукты, соглашается с предоставлением Администрации правомочий по разрешению споров, указанных в п. 6.12 Соглашения, подтверждает, что будет согласно с решением Администрации. ",
    },
  ]

  const subsections7 = [
    {
      id: "7.1.",
      indentLevel: 0,
      text: "Сайт предоставляется Пользователю «как есть». Администрация не гарантирует Пользователю достижение какого-либо конкретного результата, который тот планирует получить, если иное прямо не предусмотрено настоящим соглашением.",
    },
    {
      id: "7.2.",
      indentLevel: 0,
      text: "Ограничение ответственности Администрации:",
    },
    {
      id: "7.2.1.",
      indentLevel: 1,
      text: "Администрация не несет ответственности за посещение и использование Пользователем внешних интернет-страниц, ссылки на которые могут содержаться на Сайте. ",
    },
    {
      id: "7.2.2.",
      indentLevel: 1,
      text: "Администрация не несет ответственности за содержание и достоверность информации и иного Контента (в т.ч. предоставляемого на возмездной основе), размещенного на Сайте третьими лицами (в т.ч. Пользователями и Лицами, реализующими Продукт). Пользователь вправе предъявить свои претензии, связанные с таким Контентом, к соответствующим третьим лицам. По усмотрению Администрации Пользователю может быть предоставлена возможность направления претензий соответствующим лицам с использованием интерфейса Сайта.",
    },
    {
      id: "7.2.3.",
      indentLevel: 1,
      text: "Администрация не несет ответственности по обязательствам третьих лиц (в т.ч. Пользователей и Лиц, реализующих Продукт), а лишь содействует взаимодействию Пользователей с Лицами, реализующими Продукты, в электронной форме с использованием Сайта. ",
    },
    {
      id: "7.2.4.",
      indentLevel: 1,
      text: "Администрация не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с использованием им Контента, содержащегося на Сайте. Пользователь использует Контент и полученные из него сведения на свой страх и риск, самостоятельно несет за это ответственность перед третьими лицами.",
    },
    {
      id: "7.2.5.",
      indentLevel: 1,
      text: "Администрация не несет ответственности и не имеет каких-либо обязательств перед Пользователем в связи с рекламой, которая может быть размещена на Сайте, в т.ч. в связи с использованием Пользователем у третьих лиц товаров (работ, услуг), приобретенных им по рекламе, размещенной на Сайте.",
    },
    {
      id: "7.2.6.",
      indentLevel: 1,
      text: "Администрация не гарантирует постоянную непрерывную работу Сайта без сбоев, не гарантирует ту или иную скорость работы Сайта, его доступность из всех юрисдикций и с любых устройств. Администрация не несет ответственности в случае возникновения технических сбоев в работе Сайта, в том числе вызывающих его временную неработоспособность, не гарантирует работоспособность Сайта в конкретный момент времени. Не считаются нарушением прав Пользователей временная неработоспособность Сайта, связанная с его обновлениями, профилактическими работами или сбоями. Возникновение указанных обстоятельств не влечет возникновение у Администрации обязанности возместить Пользователю или иному лицу какие либо убытки.",
    },
    {
      id: "7.2.7.",
      indentLevel: 1,
      text: "Администрация не гарантирует Пользователю, что Сайт будет соответствовать его целям и требованиям, качество Продуктов будет соответствовать ожиданиям Пользователя, полученные с использованием Сайта вычисления и иные результаты будут являться точными и надежными.",
    },
    {
      id: "7.2.8.",
      indentLevel: 1,
      text: "Администрация не несет ответственности за вред, причиненный в связи с получением третьими лицами несанкционированного доступа к учетной записи Пользователя.",
    },
    {
      id: "7.2.9.",
      indentLevel: 1,
      text: "Администрация не несет ответственности за полноту и достоверность сведений, представленных Пользователем при регистрации на Сайте. Лицо, реализующее Продукты, обязано самостоятельно проверить полноту и достоверность указанных сведений Пользователя при заключении с ним договора.",
    },
    {
      id: "7.3",
      indentLevel: 0,
      text: "Администрация вправе в любой момент времени в одностороннем внесудебном порядке отказаться от исполнения настоящего Соглашения (расторгнуть его)  без необходимости обоснования причин. Администрация уведомляет об этом Пользователя или Лицо, реализующее Продукты, путем направления им уведомления или путем размещения уведомления на Сайте или путем блокировки (удаления) учетной записи соответствующего лица. Соглашение считается расторгнутым с момента направления (публикации) такого уведомления или блокировки (удаления) учетной записи.",
    },
  ]

  const subsections8 = [
    {
      id: "8.1.",
      indentLevel: 0,
      text: "Администрация вправе направлять Пользователям информационные сообщения, в том числе рекламного характера. Указанные материалы могут направляться как с использованием интерфейса Сайта (в учетную запись Пользователя), так и по указанным ими при регистрации адресам электронных почт и номерам телефонов. Проходя процедуру регистрации, Пользователь дает согласие на получение соответствующих сообщений.",
    },
    {
      id: "8.2.",
      indentLevel: 0,
      text: "Администрация в случае возникновения необходимости вправе запрашивать у Пользователей сведения об их личности, в т.ч. ФИО, паспортные данные, адрес электронной почты, номер телефона и др.",
    },
    {
      id: "8.3.",
      indentLevel: 0,
      text: "Пользователь и Лицо, реализующее Продукты, дают согласие на уступку Администраций при необходимости любых прав и обязанностей по настоящему Соглашению любым третьим лицам без необходимости получения дополнительного согласия. Пользователи и Лица, реализующие Продукты, вправе уступать свои права и обязанности по настоящему Соглашению только с письменного согласия Администрации.",
    },
    {
      id: "8.4.",
      indentLevel: 0,
      text: "Настоящее Соглашение вступает в силу для Пользователя с момента его присоединения к нему в порядке, установленном п. 1.5 Соглашения. Новые редакции Соглашения полностью заменяют ранее действовавшие и вступают в силу для всех Пользователей с момента их размещения на Сайте в порядке, установленном п. 1.6 Соглашения. ",
    },
    {
      id: "8.5.",
      indentLevel: 0,
      text: "Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации. При возникновении у Пользователя претензий к Администрации, до передачи спора на рассмотрение в суд он обязан направить Администрации претензию по адресу электронной почты: support@syncwoia.com. Срок рассмотрения претензии – 30 календарных дней. По истечении указанного срока Пользователь вправе обратиться в суд.",
    },
    {
      id: "8.6.",
      indentLevel: 0,
      text: "В соответствии с настоящим Соглашением изменяется территориальная подсудность споров, вытекающих из настоящего Соглашения (договорная подсудность). Споры, вытекающие из настоящего Соглашения и подсудные городским (районным) судам передаются на рассмотрение Долгопрудненского городского суда Московской области. Споры, подсудные мировым судьям передаются на рассмотрение мирового судьи по месту нахождения Администрации. Споры, подсудные арбитражным судам, передаются на рассмотрение Арбитражного суда Московской области.",
    },
    {
      id: "8.7.",
      indentLevel: 0,
      text: "Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.",
    },
    {
      id: "8.8.",
      indentLevel: 0,
      text: "Бездействие со стороны Администрации в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы и сервисы Сайта.",
    },
    {
      id: "8.9.",
      indentLevel: 0,
      text: "Любые претензии к Администрации, в том числе уведомление, предусмотренное ст. 15.7 Федерального закона от 27.07.2006 № 149-ФЗ «Об информации, информационных технологиях и о защите информации», могут быть предъявлены Пользователями и третьими лицами путем направления требования (заявления) по электронной почте: support@syncwoia.com.",
    },
  ]

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Header variant={"shadow"} />
      <BackgroundContainer>
        <TermsTextContainer>
          <DocumentTitle>
            <span>Пользовательское Соглашение Syncwoia</span>
          </DocumentTitle>
          <Paragraph>
            Настоящее пользовательское Соглашение (далее – «<strong>Соглашение</strong>») является публичной офертой и
            определяет условия использования материалов, сервисов, контента размещенных на сайте в сети Интернет по
            адресу:{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://syncwoia.com/"
              style={{ color: "blue" }}
            >
              https://syncwoia.com/
            </a>
            {" "}и его структурных элементах (далее – «<strong>Сайт</strong>»), любыми лицами, получающими доступ к Сайту
            или
            его отдельным структурным элементам (далее – «<strong>Пользователи</strong>»).
          </Paragraph>
          <ParagraphTitle>
            1. Общие условия
          </ParagraphTitle>
          {subsections1.map((section) => (
            <Subsection key={section.id}>
              <Paragraph>
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
          <ParagraphTitle>
            2. Регистрация Пользователей на Сайте
          </ParagraphTitle>
          {subsections2.map((section) => (
            <Subsection key={section.id}>
              <Paragraph>
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
          <ParagraphTitle>
            3. Контент, размещаемый на Сайте
          </ParagraphTitle>
          {subsections3.map((section) => (
            <Subsection key={section.id}>
              <Paragraph style={{
                paddingLeft: `${section.indentLevel * 2}em`,
              }}
              >
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
          <ParagraphTitle>
            4. Права, обязанности и ответственность Пользователя
          </ParagraphTitle>
          {subsections4.map((section) => (
            <Subsection key={section.id}>
              <Paragraph style={{
                paddingLeft: `${section.indentLevel * 2}em`,
              }}
              >
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
          <ParagraphTitle>
            5. Правила получения Пользователями доступа к Контенту, а также приобретению товаров и услуг с использованием
            Сайта
          </ParagraphTitle>
          {subsections5.map((section) => (
            <Subsection key={section.id}>
              <Paragraph style={{
                paddingLeft: `${section.indentLevel * 2}em`,
              }}
              >
                {section.id} {section.text}
              </Paragraph>
              {section.hasList && (
                <ul style={{ paddingLeft: `${(section.indentLevel + 3) * 2}em` }}>
                  {section.list.map((item, index) => (
                    <li key={index} style={{ fontSize: "18px", lineHeight: "1.6" }}>
                      {item}
                    </li>
                  ))}
                </ul>
              )}
            </Subsection>
          ))}
          <ParagraphTitle>
            6. Взаимодействие Администрации и Лиц, реализующих Продукты с использованием Сайта
          </ParagraphTitle>
          {subsections6.map((section) => (
            <Subsection key={section.id}>
              <Paragraph style={{
                paddingLeft: `${section.indentLevel * 2}em`,
              }}
              >
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
          <ParagraphTitle>
            7. Ответственность при использовании Сайта
          </ParagraphTitle>
          {subsections7.map((section) => (
            <Subsection key={section.id}>
              <Paragraph style={{
                paddingLeft: `${section.indentLevel * 2}em`,
              }}
              >
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
          <ParagraphTitle>
            8. Прочие условия
          </ParagraphTitle>
          {subsections8.map((section) => (
            <Subsection key={section.id}>
              <Paragraph style={{
                paddingLeft: `${section.indentLevel * 2}em`,
              }}
              >
                {section.id} {section.text}
              </Paragraph>
            </Subsection>
          ))}
        </TermsTextContainer>
      </BackgroundContainer>
      <Footer />
    </div>
  )
}

export default TermsPage
