import React, { useEffect, useRef, useState } from "react"
import MainScreen from "./mainScreen/MainScreen"
import DescriptionCompany from "./descriptionCompany/DescriptionCompany"
import Video from "./video/Video"
import Advantages from "./advantages/Advantages"
import Gallery from "./gallery/Gallery"
import Partners from "./partners/Partners"
import VideoGallery from "./videoGallery/VideoGallery"
import Reviews from "./reviews/Reviews"
import {
  Header,
  Footer,
  OrganizationFeatureList,
  OrganizationFooter,
  Catalog,
  LandingCarousel,
  Contacts,
  TeamOfOrganization,
  OrganizationAdvantages,
} from "widgets"
import Faq from "./faq/Faq"
import Feedback from "./feedback/Feedback"
import Tab from "./tab/Tab"
import buttonUpDefault from "../../images/icons/button_up_default.png"
import buttonUpHover from "../../images/icons/button_up_hover.png"
import buttonUpPressed from "../../images/icons/button_up_pressed.png"
import { IconButton, Typography } from "@mui/material"
import ProjectList from "./projectList/ProjectList"
import { axiosInstance as axios } from "shared/api/axiosInstance"
import SubscribeBlock from "./subscribeBlock/SubscribeBlock"
import Carousel from "react-material-ui-carousel"
import { CatalogWrapper } from "./styles"
import config from "../../shared/config"
import { Button } from "../../shared/ui"
import VK, { Group } from "react-vk"
import { SingleAdvantage } from "./singleAdvantage/SingleAdvantage"
import { styled } from "@mui/material/styles"
import { useWindowSizeChange } from "../../shared/hooks"
import { DescriptionBackgroundBlock } from "./descriptionBackgroundBlock/DescriptionBackgroundBlock"

const Organization = props => {
  const [titles, setTitles] = useState([])
  const refs = useRef({})
  const [upButtonOpacity, setUpButtonOpacity] = useState(0)
  const [data, setData] = useState(null)
  const [templateProps, setTemplateProps] = useState(null)
  const { width, isSmall } = useWindowSizeChange()

  useEffect(() => {
    const ref_token = localStorage.getItem("token_ref")
    if (!ref_token) {
      delete axios.defaults.headers.common["Authorization"]
    }
    getOrganization()
    window.addEventListener("scroll", () => setUpButtonOpacity(window.scrollY === 0 ? 0 : 1))
  }, [])

  function getOrganization() {
    const params = {}
    const window_url = window.location.origin
    if (window_url.split("//")[1].includes(config.hostApi.split("//")[1])) params["slug"] = props.match.params.slug
    else params["domain"] = window_url.split("//")[1]
    axios.get(`/organizations/`, { params: { ...params } }).then(res => {
      setTemplateProps(JSON.parse(res.data[0].template_props))
      setData({ ...res.data[0] })
    })
  }

  const getVkSize = wd => {
    if (width === "sm" || width === "xs") return "320px"
    if (width === "md") return "800px"
    else return "1000px"
  }

  function contentMapper(e, index) {
    switch (e.type) {
      case "features_list":
        return <OrganizationFeatureList handleChangeRef={handleChangeRef} props={e} />
      case "footer":
        return (
          <OrganizationFooter
            title={data.title}
            handleChangeRef={handleChangeRef}
            icon_logo={data.logo_negative}
            props={e}
          />
        )
      case "catalog":
        return (
          <CatalogWrapper>
            <Typography variant={"h2"} sx={{ color: e.H2?.color || "#000" }}>
              {e.H2?.text || "Каталог"}
            </Typography>
            <Catalog
              tabs={templateProps?.catalog_tabs || undefined}
              toCatalogButtonText={data.courses.length > 3 && e.buttonText ? e.buttonText : "Все события и программы"}
              handleChangeRef={handleChangeRef}
              type={"landing"}
              organization={data.id}
              index={e.index}
            />
          </CatalogWrapper>
        )
      case "vk_groups":
        return (
          <div
            key={`vk_${width}`}
            style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}
          >
            <VK>
              <Group groupId={e.id} options={{ mode: 4, height: "600px", width: getVkSize(width) }} />
            </VK>
          </div>
        )
      case "carousel":
        return <LandingCarousel refs={refs} titles={titles} props={e} />
      case "contacts":
        return <Contacts handleChangeRef={handleChangeRef} props={e} />
      case "organization_team":
        return <TeamOfOrganization props={e} handleChangeRef={handleChangeRef} />
      case "main_screen":
        return <MainScreen titles={titles} refs={refs} e={e} />
      case "description_company":
        return <DescriptionCompany handleChangeRef={handleChangeRef} e={e} reverse={false} />
      case "reverse_description_company":
        return <DescriptionCompany handleChangeRef={handleChangeRef} e={e} reverse={true} />
      case "description_background_block":
        return <DescriptionBackgroundBlock handleChangeRef={handleChangeRef} e={e} />
      case "video":
        return <Video e={e} handleChangeRef={handleChangeRef} />
      case "advantages":
        return <Advantages e={e} handleChangeRef={handleChangeRef} />
      case "advantages_new":
        return <OrganizationAdvantages handleChangeRef={handleChangeRef} props={e} />
      case "gallery":
        return <Gallery e={e} handleChangeRef={handleChangeRef} />
      case "partners":
        return <Partners e={e} handleChangeRef={handleChangeRef} />
      case "video_gallery":
        return <VideoGallery e={e} handleChangeRef={handleChangeRef} />
      case "single_advantage":
        return <SingleAdvantage handleChangeRef={handleChangeRef} e={e} />
      case "projects":
        return <ProjectList org_slug={data.slug} e={e} organization={data?.id} handleChangeRef={handleChangeRef} />
      case "reviews":
        return <Reviews e={e} handleChangeRef={handleChangeRef} />
      case "faq":
        return <Faq e={e} handleChangeRef={handleChangeRef} />
      case "feedback_form":
        return <Feedback e={e} handleChangeRef={handleChangeRef} />
      case "tab":
        return <Tab titles={titles} e={e} refs={refs} />
      case "subscribe":
        return <SubscribeBlock e={e} handleChangeRef={handleChangeRef} id={data.id} />
      default:
        return null
    }
  }

  useEffect(() => {
    if (!!templateProps?.props) {
      const newTitles = templateProps.props.map(e => e.title).filter(title => !!title)
      setTitles(newTitles)
    }
  }, [templateProps])

  function handleChangeRef(ref, index) {
    refs.current[index] = ref
  }

  if (!data) return <></>

  return (
    <MainContainer style={{ width: "100%" }}>
      {!!data?.icon_logo && (
        <Header
          buttonText={templateProps?.header_text || "КАТАЛОГ"}
          variant={"default"}
          slug={data.slug}
          catalogLink={`/catalog/organization/${data?.id}`}
          logo={data.logo}
          headerItems={templateProps.header_items.map(e => ({ label: e.label, ref: refs.current[e.to_block] }))}
        />
      )}
      {!!templateProps?.props &&
        templateProps.props
          .sort((a, b) => (Number(a?.index) > Number(b?.index) ? 1 : -1))
          .map((e, index) => contentMapper(e, index))}
      <IconButton
        disableRipple
        style={{
          position: "fixed",
          right: "2%",
          bottom: "10%",
          zIndex: 10,
          cursor: "pointer",
          opacity: upButtonOpacity,
        }}
      >
        <img
          src={buttonUpDefault}
          onMouseOver={e => (e.currentTarget.src = buttonUpHover)}
          onMouseOut={e => (e.currentTarget.src = buttonUpDefault)}
          onMouseDown={e => (e.currentTarget.src = buttonUpPressed)}
          onMouseUp={e => (e.currentTarget.src = buttonUpHover)}
          onClick={() => {
            window.scrollTo(0, 0)
          }}
        />
      </IconButton>
      <Footer />
    </MainContainer>
  )
}

export default Organization

const MainContainer = styled("div")(({ theme }) => ({
  width: "100%",
}))
